<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.employees.create.title' | translate }}
    v-card-content
      v-select.mb-3(
        :label="$t('base.user_groups')"
        :options="types"
        itemValue="value"
        v-model="form.user_type")
      v-text-field.mb-3(
        :label="$t('base.first_name')"
        v-model="form.name")
      v-text-field.mb-3(
        :label="$t('base.last_name')"
        v-model="form.surname")
      v-text-field.mb-3(
        :label="$t('base.phone')"
        v-model="form.phone")
      v-text-field.mb-3(
        :label="$t('base.email')"
        v-model="form.email")
      v-password-field(
        :label="$t('base.password')"
        v-model="form.password")
    v-card-actions
      v-btn(
        :disabled="!isUserTypeSelected || !isNameValid || !isSurnameValid || !isPhoneValid || !isEmailValid || !isPasswordValid"
        :loading="loading"
        @click="onCreate")
        | {{ 'actions.create' | translate }}
</template>

<script>
import { USER_TYPES } from '@/util/consts.js'
import { mapActions } from 'vuex'

export default {
  name: 'CreateNewModal',

  data: () => ({
    form: {
      user_type: null,
      name: null,
      surname: null,
      phone: null,
      email: null,
      password: null
    },
    types: Object.entries(USER_TYPES)
      .map(item => ({
        value: item[0],
        name: item[1]
      })),
    loading: false
  }),

  computed: {
    isUserTypeSelected () {
      return this.form.user_type !== null
    },

    isNameValid () {
      return !!this.form.name
    },

    isSurnameValid () {
      return !!this.form.surname
    },

    isPhoneValid () {
      return !!this.form.phone
    },

    isEmailValid () {
      return !!this.form.email
    },

    isPasswordValid () {
      return !!this.form.password
    }
  },

  methods: {
    ...mapActions(['addUser']),
    async onCreate () {
      this.loading = true
      const payload = {
        email: this.form.email,
        password: this.form.password,
        first_name: this.form.name,
        last_name: this.form.surname,
        role: this.form.user_type,
        phone: this.form.phone
      }
      const { data, error } = await this.addUser(payload)
      this.loading = false
      if (error) {
        for (const key in data.errors) {
          this.$toasted.show(data.errors[key], {
            position: 'bottom-center',
            duration: 5000
          })
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
